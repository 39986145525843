import fetch from "auth/FetchInterceptor";
import { fetchStimeService } from "auth/FetchGC";
import { AUTH_TOKEN } from "constants/AuthConstant";

const UserService = {};

// Lấy thông tin người dùng
UserService.getProfile = function () {
  return fetchStimeService({
    url: "/user/profile",
    method: "get",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};

// Upload file lên G2
UserService.uploadToG2 = function (formData) {
  return fetch({
    url: "https://g2-dev.globalcare.vn/upload",
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// Cập nhật thông tin người dùng
UserService.updateProfile = function (data) {
  return fetchStimeService({
    url: "/user/profile",
    method: "patch",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};

// Đổi mật khẩu
UserService.changePassword = function (data) {
  return fetchStimeService({
    url: "/user/password",
    method: "patch",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};

export default UserService;
