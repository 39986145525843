import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function ({username, password}) {
	const data = {
		"clientType": "portal",
		"credentials": {
			"username": username,
			"password":	password
		}
	}
	return fetch({
		url: '/auth/token',
		method: 'post',
		data: data
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

export default AuthService;