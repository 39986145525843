import fetch from "auth/FetchInterceptor";
import { v4 as uuidv4 } from "uuid";
import md5 from "md5";
import qs from "qs";  // Sử dụng cache lưu trữ secretKey

// export const fetchStimeService = async (configApi) => {

//   const resInit = await fetch({
//     url: "/auth/secret-key",
//     method: "get",
//   });
//   if (resInit.success) {
//     const { data: { stime, secretKey } } = resInit;
    
//     let body = configApi?.data || null 
//     let queryString = configApi?.params || null
    
//     configApi.headers = getHeaders(stime, body, queryString, secretKey);
//     const res = await fetch(configApi);
//     return res;
   
//   }
//   return resInit;
// };

// const getHeaders = (stime, body, queryString, secretKey) => {
//   const env = process.env.REACT_APP_NODE_ENV;
//   const uuid = uuidv4() 
//   let client = "";

 
//   if (body) {
//     const data = qs.stringify(sortObjectByKey(body))
//     console.log("data2", data)
//     client = md5(env + "-" + data + stime + uuid + secretKey);

//   // Trường hợp có query string
//   } else if (queryString) {
//     const data = qs.stringify(sortObjectByKey(queryString))
//     client = md5(env + "-" + data + stime + uuid + secretKey);

//   // Trường hợp không có body và query string
//   } else {
//     client = md5(env + "-" + stime + uuid + secretKey);
//   }

//   return {
//     "x-api-stime": stime,
//     "x-api-nonce": uuid,
//     "x-api-client": client,
//   };
// };

// const sortObjectByKey = (obj) => {
//   if (!obj) return {}; 
//   // Get the keys of the object and sort them in ASCII order
//   const sortedKeys = Object.keys(obj).sort();

//   // Construct a new object using the sorted keys
//   const sortedObject = {};
//   sortedKeys.forEach((key) => {
//     sortedObject[key] = obj[key];
//   });

//   return sortedObject;
// };

let isFetchingSecretKey = false; // Trạng thái kiểm tra xem secretKey đã được lấy hay chưa
let secretKeyData = null; // Lưu trữ secretKey và stime
let secretKeyExpiry = null; // Lưu trữ thời gian hết hạn của secretKey (30s)

// Hàm gọi API secret-key để lấy secretKey và stime
const getSecretKey = async () => {
  if (isFetchingSecretKey) {
    // Nếu đã có yêu cầu secretKey đang được thực hiện, thì đợi
    await new Promise(resolve => {
      const interval = setInterval(() => {
        if (secretKeyData) {
          clearInterval(interval); // Khi có secretKey, dừng lại
          resolve();
        }
      }, 100); // Kiểm tra mỗi 100ms
    });
  } else {
    isFetchingSecretKey = true; // Đánh dấu là đang lấy secretKey
    try {
      const resInit = await fetch({
        url: "/auth/secret-key",
        method: "get",
      });

      if (resInit.success) {
        const { data: { stime, secretKey } } = resInit;
        secretKeyData = { stime, secretKey };
        secretKeyExpiry = Date.now() + 30000; // SecretKey hết hạn sau 30s
      }
    } catch (error) {
      console.error("Error fetching secretKey:", error);
    } finally {
      isFetchingSecretKey = false; // Reset trạng thái
    }
  }
};

// Hàm để kiểm tra và gọi lại secretKey khi cần
const checkAndRefreshSecretKey = async () => {
  if (!secretKeyData || Date.now() > secretKeyExpiry) {
    // Nếu không có secretKey hoặc hết hạn, gọi lại secretKey
    await getSecretKey();
  }
};

// Fetch dữ liệu API, sẽ gọi secretKey nếu cần
export const fetchStimeService = async (configApi) => {
  // Kiểm tra và lấy lại secretKey nếu cần
  await checkAndRefreshSecretKey();

  const { stime, secretKey } = secretKeyData;

  let body = configApi?.data || null;
  let queryString = configApi?.params || null;

  configApi.headers = getHeaders(stime, body, queryString, secretKey);
  const res = await fetch(configApi);
  return res;
};

// Helper function để tạo headers
const getHeaders = (stime, body, queryString, secretKey) => {
  const env = process.env.REACT_APP_NODE_ENV;
  const uuid = uuidv4();
  let client = "";

  if (body) {
    const data = qs.stringify(sortObjectByKey(body));
    client = md5(env + "-" + data + stime + uuid + secretKey);
  } else if (queryString) {
    const data = qs.stringify(sortObjectByKey(queryString));
    client = md5(env + "-" + data + stime + uuid + secretKey);
  } else {
    client = md5(env + "-" + stime + uuid + secretKey);
  }

  return {
    "x-api-stime": stime,
    "x-api-nonce": uuid,
    "x-api-client": client,
  };
};

// Sort object keys alphabetically
const sortObjectByKey = (obj) => {
  if (!obj) return {};
  const sortedKeys = Object.keys(obj).sort();
  const sortedObject = {};
  sortedKeys.forEach((key) => {
    sortedObject[key] = obj[key];
  });
  return sortedObject;
};