import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/UserService';

// Thunk để gọi API cập nhật thông tin người dùng
export const updateUserProfile = createAsyncThunk(
    'user/updateUserProfile',
    async (profileData, { rejectWithValue }) => {
      try {
        const response = await UserService.updateProfile(profileData);
        if (response.success) {
          return response.data;
        } else {
          return rejectWithValue(response.message || 'Failed to update profile');
        }
      } catch (error) {
        return rejectWithValue(error.message || 'An error occurred');
      }
    }
  );
  
  const updateProfile = createSlice({
    name: 'user',
    initialState: {
      userProfile: {},
      status: 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(updateUserProfile.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(updateUserProfile.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.userProfile = { ...state.userProfile, ...action.payload };
          state.error = null;
        })
        .addCase(updateUserProfile.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        });
    },
  });
  
  export default updateProfile.reducer;