import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import product from './slices/productSlice'
import profile from './slices/profileSlice'
import user from './slices/updateprofileSlice'


const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        product,
        profile,
        user,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
